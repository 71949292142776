import React from 'react';
import PropTypes from 'prop-types';

const PaginationControl = ({
  pageIndex,
  pageOptions,
  gotoPage,
  nextPage,
  previousPage,
  canNextPage,
  canPreviousPage,
  pageCount,
}) => (
  <div className="text-center mb-3">
    <div className="filter-pagination mt-3">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        {'<<'}
      </button>
      <button
        type="button"
        className="btn btn-primary"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        {'<'}
      </button>
      <button
        type="button"
        className="btn btn-primary"
        onClick={nextPage}
        disabled={!canNextPage}
      >
        {'>'}
      </button>
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        {'>>'}
      </button>
    </div>
    <div className="d-flex justify-content-between">
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>
      <div>
        <label>
          Page:{' '}
          <input
            type="number"
            className="form-control d-inline-block"
            style={{ width: '80px' }}
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </label>
      </div>
    </div>
  </div>
);

PaginationControl.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  pageOptions: PropTypes.array.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
};

export default PaginationControl;
