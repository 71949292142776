/**
 * Renders an "Updated" span element if the provided date falls within the specified range.
 *
 * @param {string | Date} updatedAt - The date to check against the range. Can be a string (ISO format) or a Date object.
 * @param {number} [rangeInMinutes=5] - The range in minutes to determine if the "Updated" span should be shown.
 *                                      Defaults to 5 minutes if not provided.
 * @returns {JSX.Element | null} - Returns a <span> element with the class "updated-span" if the `updatedAt` date
 *                                 falls within the range; otherwise, returns `null`.
 */
export const renderUpdatedSpan = (updatedAt, rangeInMinutes = 5) => {
  const now = new Date();
  const rangeStart = new Date(now - rangeInMinutes * 60 * 1000);

  if (!updatedAt) return null;

  const updatedDate = new Date(updatedAt);
  if (updatedDate > rangeStart && updatedDate <= now) {
    return <span className="updated-span">Updated</span>;
  }

  return null;
};
