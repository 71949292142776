import { useEffect } from 'react';

const useFilterInstance = (filterInstance, tableInstance, componentName) => {
  useEffect(() => {
    const doesColumnExist = (columnName) =>
      tableInstance?.allColumns?.some((col) => col.id === columnName);

    const restoreTemporaryFilters = () => {
      const temporaryFilters = JSON.parse(
        localStorage.getItem(`${componentName}TemporaryFilters`) || '[]',
      );

      if (Array.isArray(temporaryFilters) && temporaryFilters.length > 0) {
        temporaryFilters.forEach(({ id, value }) => {
          if (doesColumnExist(id)) {
            tableInstance.setFilter(id, value);
          }
        });
      }
    };

    if (filterInstance?.columnName && filterInstance?.filterValue) {
      const currentFilters = JSON.parse(
        localStorage.getItem(`${componentName}Filters`) || '[]',
      );

      localStorage.setItem(
        `${componentName}TemporaryFilters`,
        JSON.stringify(currentFilters),
      );

      tableInstance.setAllFilters([]);

      if (doesColumnExist(filterInstance.columnName)) {
        tableInstance.setFilter(
          filterInstance.columnName,
          filterInstance.filterValue,
        );
      }
    } else {
      restoreTemporaryFilters();

      if (
        filterInstance?.columnName &&
        doesColumnExist(filterInstance.columnName)
      ) {
        tableInstance.setFilter(filterInstance.columnName, undefined);
      }
    }
  }, [filterInstance, tableInstance, componentName]);
};

export default useFilterInstance;
