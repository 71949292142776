import { getNominationRoundYear } from './getNominationRoundYear';

/**
 * Extracts and sets the report uploaded year from the referee object.
 *
 * @param {Object} referee - The referee object containing nested report data.
 * @returns {number|null} - The report date exists, otherwise null.
 */
export const getReportRoundYear = (referee) => {
  const createdAt = referee?.report?.data?.attributes?.createdAt;
  const updatedAt = referee?.report?.data?.attributes?.updatedAt;

  if (!createdAt || !updatedAt) return null;

  const createdDate = new Date(createdAt);
  const updatedDate = new Date(updatedAt);

  const createdNormalized = new Date(createdDate.setMilliseconds(0));
  const updatedNormalized = new Date(updatedDate.setMilliseconds(0));

  const reportDate =
    updatedNormalized.getTime() > createdNormalized.getTime()
      ? updatedDate
      : createdDate;
  return getNominationRoundYear(reportDate);
};
