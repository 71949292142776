import { useCallback, useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

const useColumnVisibility = (columns, componentName, toggleHideColumn) => {
  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    `${componentName}ColumnVisibility`,
    columns.reduce((acc, col) => {
      acc[col.accessor] = true;
      return acc;
    }, {}),
  );

  const getHiddenColumns = useCallback(() => {
    const alwaysVisibleColumns = ['candidate', 'actions'];

    if (Object.keys(columnVisibility).length === 0) {
      return [];
    }

    return columns
      .filter(
        (col) =>
          !alwaysVisibleColumns.includes(col.accessor) &&
          !columnVisibility[col.accessor],
      )
      .map((col) => col.accessor);
  }, [columns, columnVisibility]);

  const toggleColumnVisibility = useCallback(
    (accessor) => {
      setColumnVisibility((prev) => {
        const initializedVisibility = columns.reduce((acc, col) => {
          acc[col.accessor] = prev[col.accessor] ?? true;
          return acc;
        }, {});

        const updatedVisibility = {
          ...initializedVisibility,
          [accessor]: !initializedVisibility[accessor],
        };

        return updatedVisibility;
      });
    },
    [setColumnVisibility, columns],
  );

  useEffect(() => {
    if (toggleHideColumn) {
      Object.keys(columnVisibility).forEach((accessor) => {
        toggleHideColumn(accessor, !columnVisibility[accessor]);
      });
    }
  }, [columnVisibility, toggleHideColumn]);

  return {
    columnVisibility,
    toggleColumnVisibility,
    getHiddenColumns,
  };
};

export default useColumnVisibility;
