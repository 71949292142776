import React from 'react';
import PropTypes from 'prop-types';
import CsvExport from './CsvExport';
import DropdownPageSize from './DropdownPageSize';
import DropdownColumnVisibility from './DropdownColumnVisibility';
import DropdownViewMode from './DropdownViewMode';

const TableControls = ({
  rows,
  columns,
  pageSize,
  setPageSize,
  setTablePageSize,
  columnVisibility,
  handleColumnToggle,
  filename,
  isSectionalCommitteeFormatted,
  setIsSectionalCommitteeFormatted,
}) => (
  <div className="table-controls mb-3">
    <div className="table-control">
      <CsvExport
        rows={rows}
        columns={columns}
        columnVisibility={columnVisibility}
        filename={filename}
      />
    </div>
    <div className="table-control">
      <DropdownViewMode
        isSectionalCommitteeFormatted={isSectionalCommitteeFormatted}
        setIsSectionalCommitteeFormatted={setIsSectionalCommitteeFormatted}
      />
    </div>
    <div className="table-control">
      <DropdownPageSize
        pageSize={pageSize}
        setPageSize={setPageSize}
        setTablePageSize={setTablePageSize}
      />
    </div>
    <div className="table-control">
      <DropdownColumnVisibility
        columns={columns}
        columnVisibility={columnVisibility}
        handleColumnToggle={handleColumnToggle}
      />
    </div>
  </div>
);

TableControls.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setTablePageSize: PropTypes.func.isRequired,
  columnVisibility: PropTypes.object.isRequired,
  handleColumnToggle: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  isSectionalCommitteeFormatted: PropTypes.bool.isRequired,
  setIsSectionalCommitteeFormatted: PropTypes.func.isRequired,
};

export default TableControls;
