import React, { useContext, useEffect, useReducer, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Collapse from 'react-bootstrap/Collapse';
import { Link, NavLink } from 'react-router-dom';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { MenuList } from './Menu';
import { ThemeContext } from '../../../context/ThemeContext';
import { useLoggedInUserContextProvider } from '../../../context/LoggedInUserContext';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: '',
  activeSubmenu: '',
};

const handleMailto = (email) => {
  window.open(`mailto:${email}`, '_blank');
};

const SideBar = () => {
  const { userDetails } = useLoggedInUserContextProvider();

  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);

  const handleheartBlast = document.querySelector('.heart');
  function heartBlast() {
    return handleheartBlast.classList.toggle('heart-blast');
  }

  useEffect(() => {}, []);

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll],
  );

  const handleMenuActive = (menuTitle, hasChildren) => {
    if (hasChildren) {
      setState({
        active: state.active === menuTitle ? '' : menuTitle,
        activeSubmenu: '',
      });
    } else {
      setState({
        active: state.active === menuTitle ? '' : menuTitle,
        activeSubmenu: '',
      });
    }
  };

  const handleSubmenuActive = (submenuTitle, parentTitle) => {
    setState({
      activeSubmenu: state.activeSubmenu === submenuTitle ? '' : submenuTitle,
      active: parentTitle,
    });
  };

  const getSidebarClass = () => {
    if (
      sidebarposition.value === 'fixed' &&
      sidebarLayout.value === 'horizontal' &&
      headerposition.value === 'static'
    ) {
      return hideOnScroll > 120 ? 'fixed' : '';
    }
    return '';
  };

  const menuItems = MenuList(handleMailto);

  return (
    <div className={`dlabnav border-right ${iconHover} ${getSidebarClass()}`}>
      <PerfectScrollbar className="dlabnav-scroll">
        <ul className="metismenu" id="menu">
          <li className="menu-title" />
          {userDetails &&
            menuItems.map((data, index) => {
              const listItemClass =
                data.content && data.content.length > 0 ? '' : 'no-over-flow';
              const hasAccess =
                Array.isArray(userDetails.roles) &&
                data.access.some((role) => userDetails.roles.includes(role));

              if (!hasAccess) {
                return null;
              }
              const menuClass = data.classChange;
              if (menuClass === 'menu-title') {
                return <li className={menuClass}>{data.title}</li>;
              }
              return (
                <li
                  className={`${
                    state.active === data.title
                      ? 'mm-active'
                      : `${listItemClass}`
                  }`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link
                        to="#"
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        <i className={data.iconStyle} />
                        <span className="nav-text">{data.title}</span>
                      </Link>
                      <Collapse in={state.active === data.title}>
                        <ul
                          className={`${
                            menuClass === 'mm-collapse' ? 'mm-show' : ''
                          }`}
                        >
                          {data.content &&
                            data.content.map((submenu, dataIndex) => (
                              <li
                                key={dataIndex}
                                className={`${
                                  state.activeSubmenu === submenu.title
                                    ? 'active-submenu-item'
                                    : ''
                                }`}
                              >
                                {submenu.custom && submenu.render()}

                                {!submenu.custom &&
                                  submenu.content &&
                                  submenu.content.length > 0 && (
                                    <NavLink
                                      to={submenu.to}
                                      className={({ isActive }) =>
                                        `dropdown-item ${submenu.hasMenu ? 'has-arrow' : ''} ${
                                          isActive ? 'active-submenu-item' : ''
                                        }`
                                      }
                                      onClick={() =>
                                        handleSubmenuActive(
                                          submenu.title,
                                          data.title,
                                        )
                                      }
                                    >
                                      {submenu.title}
                                    </NavLink>
                                  )}

                                {!submenu.custom &&
                                  (!submenu.content ||
                                    submenu.content.length === 0) && (
                                    <Link
                                      to={submenu.to}
                                      onClick={() =>
                                        handleSubmenuActive(
                                          submenu.title,
                                          data.title,
                                        )
                                      }
                                    >
                                      {submenu.title}
                                    </Link>
                                  )}
                              </li>
                            ))}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <NavLink
                      to={data.to}
                      className={({ isActive }) =>
                        `custom-hover ${isActive ? 'active-menu-item' : ''}`
                      }
                      onClick={() => handleMenuActive(data.title, false)}
                    >
                      <i className={data.iconStyle} />
                      <span className="nav-text">{data.title}</span>
                    </NavLink>
                  )}
                </li>
              );
            })}
        </ul>

        <div className="copyright mt-0 position-absolute bottom-0 w-100">
          <p>
            <strong>Fellowship Nomination System</strong> ©{' '}
            {new Date().getFullYear()} All Rights Reserved
          </p>
          <p className="fs-12">
            Made with
            <span
              className="heart"
              onClick={() => heartBlast()}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') heartBlast();
              }}
              role="button"
              tabIndex="0"
              aria-label="Toggle heart animation"
            />{' '}
            by AAS
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
