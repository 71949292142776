import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { CommonCollections } from '../../App';
import ModalConfirmation from './ModalConfirmation';
import { axiosPost } from '../../utils/axiosPost';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';
import ButtonIcon from './Forms/Elements/ButtonIcon';
import DownloadFile from './Forms/Elements/DownloadFile';
import { strapiUrl } from '../../utils/constants';

const ActionButtons = ({ row, onEmailApiErrorChange }) => {
  const { userDetails } = useLoggedInUserContextProvider();

  const {
    activeFellowsArray,
    electionTypes,
    titlesTypes,
    nonAasRefereesArray,
  } = useStaticCollections();

  const { nominationArray } = useAdminCollections();

  const {
    setRefereeReportId,
    refreshRefereeReports,
    setRefreshRefereeReports,
  } = useContext(CommonCollections);

  const [showModal, setShowModal] = useState(false);
  const [pendingApprovalOption, setPendingApprovalOption] = useState(null);
  const [refereeEmailType, setRefereeEmailType] = useState('');
  const [isEmailApiError, setIsEmailApiError] = useState(null);

  const {
    id,
    candidate,
    nominationId,
    refereeFellowId,
    refereeUser,
    refereeUserId,
    nonAasRefereeId,
    refereeStatusId,
    requestEmailDate,
    sectionalCommittee,
    nominationStatusTypeId,
    refereeArchived,
    reportFile,
    reportUploadedYear,
  } = row.original;

  let canEdit = false;
  if (!refereeArchived) {
    if (
      refereeStatusId === 1 ||
      refereeStatusId === 6 ||
      refereeStatusId === 5
    ) {
      if (
        nominationStatusTypeId !== 4 &&
        nominationStatusTypeId !== 5 &&
        nominationStatusTypeId !== 6 &&
        nominationStatusTypeId !== 7
      ) {
        canEdit = true;
      }
    }
  }
  const buttonIcon = canEdit
    ? 'bi bi-pen action-button'
    : 'bi bi-eye view-button';
  const buttonToolTipText = canEdit
    ? `Edit ${refereeUser}'s report`
    : `View ${refereeUser}'s report`;

  const handleClick = () => {
    setRefereeReportId(id);
  };

  const sendRefereeReportReminderEmail = () => {
    setRefereeEmailType('reminder');
    setShowModal(true);
  };

  const sendRefereeReportRequestEmail = () => {
    setRefereeEmailType('request');
    setShowModal(true);
  };

  const handleCancelSelect = () => {
    if (isEmailApiError) {
      setIsEmailApiError(null);
      onEmailApiErrorChange(null);
    }
    setShowModal(false);
  };

  const handleConfirmSelect = async () => {
    if (isEmailApiError) {
      setIsEmailApiError(null);
      onEmailApiErrorChange(null);
    }

    const activeFellow = activeFellowsArray.find(
      (fellow) => fellow.id === refereeFellowId,
    );

    const nonAasReferee = nonAasRefereesArray.find(
      (nonAasRefereeInstance) => nonAasRefereeInstance.id === nonAasRefereeId,
    );

    const refereeData = {};
    refereeData.nominationId = nominationId;
    refereeData.candidate = candidate;
    refereeData.referees = [
      {
        refereeUserId,
        refereeFellowId,
        nonAasRefereeId,
      },
    ];
    const refereeNominationTypeId = nominationArray.find(
      (item) => item.id === nominationId,
    );

    refereeData.sectionalCommittee =
      sectionalCommittee ??
      electionTypes[refereeNominationTypeId?.attributes?.electionTypeId];
    refereeData.testEmail = userDetails.email;

    if (refereeEmailType === 'request') {
      refereeData.electionTypeId =
        refereeNominationTypeId?.attributes?.electionTypeId ?? '';
    }

    if (refereeEmailType === 'reminder') {
      refereeData.salutation =
        activeFellow?.attributes?.salutationId !== undefined
          ? titlesTypes[activeFellow.attributes.salutationId]
          : titlesTypes[nonAasReferee.attributes.titleId]; // todo: unify titleId and salutionId

      refereeData.proposer = `${refereeData.salutation ?? ''} ${refereeUser}`;
    }

    const maxRetries = 3;
    let retries = 0;
    let emailSent = false;

    while (retries < maxRetries && !emailSent) {
      const emailRefereeReportResponse = await axiosPost(
        `email-referee-report-${refereeEmailType}`,
        {
          data: refereeData,
        },
      );

      if (
        emailRefereeReportResponse?.status === 200 &&
        emailRefereeReportResponse?.data?.emailApiResponse?.[0]?.[0]?.Status ===
          'Accepted'
      ) {
        setTimeout(() => {
          setRefereeReportId(id);
          setRefreshRefereeReports(!refreshRefereeReports);

          setTimeout(() => {
            setShowModal(false);
          }, 1500);

          setIsEmailApiError(false);
          onEmailApiErrorChange(false);
        }, 2000);
        emailSent = true;
      } else {
        retries += 1;
        if (retries === maxRetries) {
          console.log(
            'Failed to send email after multiple attempts, setting error to true.',
          );
          setIsEmailApiError(true);
          onEmailApiErrorChange(true);
        } else {
          console.log(`Retrying... Attempt ${retries} of ${maxRetries}`);
        }
      }
    }
  };

  const approveReferee = async () => {
    setRefereeEmailType('request');
    setPendingApprovalOption('approve');
    setShowModal(true);
  };

  const declineReferee = async () => {
    setPendingApprovalOption('decline');
    setShowModal(true);
  };

  const removeNonAasRefereeSessionStorage = () => {
    const keyToRemove =
      'non-aas-referees?sort=lastName&pagination[limit]=6000&filters[isActive][$eq]=true';
    sessionStorage.removeItem(keyToRemove);
  };

  const approveRefereeConfirmSelect = async () => {
    const response = await axiosPost('referee-approve', {
      data: { id },
    });

    if (response && response?.status === 200) {
      setTimeout(() => {
        handleConfirmSelect();
      }, 2000);
      removeNonAasRefereeSessionStorage();
    }
  };

  const declineRefereeConfirmSelect = async () => {
    const response = await axiosPost('referee-decline', {
      data: { id },
    });

    if (response && response?.status === 200) {
      setTimeout(() => {
        setRefereeReportId(id);
        setRefreshRefereeReports(!refreshRefereeReports);
      }, 2000);
      removeNonAasRefereeSessionStorage();
      setShowModal(false);
    }
  };

  return (
    <>
      {requestEmailDate !== 'Pending approval' && (
        <>
          <Link to="/referee-report-administration" onClick={handleClick}>
            <span title={buttonToolTipText}>
              <i className={buttonIcon} />
            </span>
          </Link>
          <ModalConfirmation
            showModal={showModal}
            handleCancelSelect={handleCancelSelect}
            bodyText={`Are you sure you want to send a **referee report ${refereeEmailType} email** to **${refereeUser}** for candidate **${candidate}?**`}
            handleConfirmSelect={handleConfirmSelect}
            rowId={`${id}`}
          />
        </>
      )}

      {requestEmailDate === 'Pending approval' && (
        <>
          <ButtonIcon
            bootstrapIcon="bi bi-square"
            backgroundColor="bg-success"
            handleClick={approveReferee}
            handleKeyPress={approveReferee}
            tooltipText={`Approve referee ${refereeUser}`}
          />
          <ButtonIcon
            bootstrapIcon="bi bi-x-circle"
            backgroundColor="bg-danger"
            handleClick={declineReferee}
            handleKeyPress={declineReferee}
            tooltipText={`Decline referee ${refereeUser}`}
          />
          <ModalConfirmation
            showModal={showModal}
            handleCancelSelect={handleCancelSelect}
            bodyText={`Are you sure you want to **${pendingApprovalOption}** **${refereeUser}** as a referee?`}
            handleConfirmSelect={
              pendingApprovalOption === 'approve'
                ? approveRefereeConfirmSelect
                : declineRefereeConfirmSelect
            }
            rowId={`${id}`}
          />
        </>
      )}
      {reportFile && (
        <DownloadFile
          text={`Report Round Year : ${reportUploadedYear}`}
          url={`${strapiUrl}${reportFile}`}
          name=""
          buttonClassName="action-button-two"
          iconClassName="download-button"
          showFileName={false}
          showText={false}
        />
      )}

      {canEdit && requestEmailDate !== 'Pending approval' && (
        <>
          <Link onClick={sendRefereeReportRequestEmail}>
            <span title="Send Referee Report request Email">
              <i className="bi bi-envelope email-button" />
            </span>
          </Link>
          <Link onClick={sendRefereeReportReminderEmail}>
            <span title="Send Referee Report Reminder Email">
              <i className="bi bi-reply resend-button" />
            </span>
          </Link>
        </>
      )}
    </>
  );
};

ActionButtons.propTypes = {
  row: PropTypes.object,
  onEmailApiErrorChange: PropTypes.func.isRequired,
};

export default ActionButtons;
