import React, { useEffect, useState, useContext, useCallback } from 'react';
import FilteringTable from './Table/FilteringTable/FilteringTable';
import ColumnFilter from './Table/FilteringTable/ColumnFilter';
import PageTitle from '../layouts/PageTitle';

import { tableColumnNamesRefereeAdmin } from '../../utils/tableColumnNamesRefereeAdmin';
import { arrayToFlatObject } from '../../utils/arrayToFlatObject';
import { tableRowsValuesRefereeAdministration } from '../../utils/tableRowsValuesRefereeAdministration';
import { CommonCollections } from '../../App';
import AdminRefereeReportCell from './AdminRefereeReportCell';
import { filteringTableGetTableColumns } from '../../utils/filteringTableGetTableColumns';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { getReportRoundYear } from '../../utils/getReportRoundYear';

const AdminRefereeReport = () => {
  const {
    fellows,
    declineReasons,
    nonAasReferees,
    nominationStatusTypes,
    refereeStatuses,
    sectionalCommitteesWithCmse,
    users,
  } = useStaticCollections();

  const { candidates, nominationArray } = useAdminCollections();

  const { refereeReports, refreshDynamicCollections, setRefereeReportId } =
    useContext(CommonCollections);

  const [refereesData, setRefereesData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [filterInstance, setFilterInstance] = useState(null);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [isEmailApiError, setIsEmailApiError] = useState(false);
  const [isNotificationTemporary, setIsNotificationTemporary] = useState(false);

  useEffect(() => {
    const tableColumns = filteringTableGetTableColumns(
      tableColumnNamesRefereeAdmin,
      ColumnFilter,
      (props) => (
        <AdminRefereeReportCell
          {...props}
          onEmailApiErrorChange={(error) => {
            if (error) {
              setNotificationMessage(
                'Error: campaign Monitor email failed; try again.',
              );
              setNotificationTitle('');
              setIsNotificationTemporary(false);
            } else {
              setNotificationMessage('Email has been sent successfully!');
              setNotificationTitle('');
              setIsNotificationTemporary(true);
              setTimeout(() => {
                setIsNotificationTemporary(false);
              }, 6000);
            }
            setIsEmailApiError(error);
          }}
        />
      ),
    );

    const tableData = tableRowsValuesRefereeAdministration(
      refereeReports,
      refereeStatuses,
      declineReasons,
    );

    setTableColumn(tableColumns);
    const refereeData = [tableData.map((item) => item.nominationId), tableData];
    const nominationArr = nominationArray.map((item) => ({
      [item.id]: item.attributes,
    }));

    const nominationsObj = arrayToFlatObject(nominationArr);
    const tableDataTwo = Object.values(refereeData[1]).map((referee) => {
      if (nominationsObj[referee.nominationId] !== undefined) {
        referee.candidate =
          candidates[nominationsObj[referee.nominationId].candidateId];
        referee.sectionalCommittee =
          sectionalCommitteesWithCmse[
            nominationsObj[referee.nominationId]?.sectionalCommitteeId ?? 14
          ];
        referee.nominationStatus =
          nominationStatusTypes[
            nominationsObj[referee.nominationId].nominationStatusTypeId
          ];
        referee.nominationStatusTypeId =
          nominationsObj[referee.nominationId].nominationStatusTypeId;
      }
      referee.refereeUser = referee.refereeFellowId
        ? fellows[referee.refereeFellowId]
        : nonAasReferees[referee.nonAasRefereeId];
      referee.reportFile = referee.report.data
        ? referee.report.data.attributes.url
        : null;
      referee.reportUploadedYear = getReportRoundYear(referee);

      return referee;
    });

    setRefereesData(tableDataTwo);
    setRefereeReportId(null);
  }, [
    refereeStatuses,
    sectionalCommitteesWithCmse,
    users,
    declineReasons,
    fellows,
    candidates,
    nonAasReferees,
    nominationArray,
    refereeReports,
    refreshDynamicCollections,
    setRefereeReportId,
    nominationStatusTypes,
  ]);

  const resetNotification = useCallback(() => {
    const pendingApproval = refereesData.filter(
      (item) => item.requestEmailDate === 'Pending approval',
    );

    const pendingApprovalCount = pendingApproval.length;
    const candidateNames = pendingApproval
      .map((entry) => ` ${entry.refereeUser} (${entry.candidate})`)
      .join('\n');

    setNotificationTitle(
      `Approval required for ${pendingApprovalCount} referees:`,
    );
    setNotificationMessage(candidateNames);
  }, [refereesData]);

  useEffect(() => {
    if (!isEmailApiError && !isNotificationTemporary) {
      resetNotification();
    }
  }, [isEmailApiError, isNotificationTemporary, resetNotification]);

  return (
    <>
      <PageTitle
        activeMenu="Referee Administration"
        motherMenu="Home"
        notificationMessage={notificationMessage}
        notificationTitle={notificationTitle}
        setFilterInstance={setFilterInstance}
      />
      <FilteringTable
        componentName="AdminRefereeReport"
        columns={tableColumn}
        data={refereesData}
        filterInstance={filterInstance}
      />
    </>
  );
};

export default AdminRefereeReport;
