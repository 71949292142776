import React, {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  lazy,
  Suspense,
  useState,
} from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
  useSortBy,
} from 'react-table';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './filtering.css';

import FilterDisplay from './FilterDisplay';
import GlobalFilter from './GlobalFilter';
import PaginationControl from './PaginationControl';
import TableBody from './TableBody';
import TableControls from './TableControls';
import TableHeader from './TableHeader';

import { CommonCollections } from '../../../../App';
import useFetchMarkdown from '../../../hooks/useFetchMarkdown';
import useFilterInstance from '../../../hooks/useFilterInstance';
import useColumnVisibility from '../../../hooks/useColumnVisibility';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useTableFilters from '../../../hooks/useTableFilters';

import applyColumnVisibility from '../../../../utils/applyColumnVisibility';
import formatSectionalCommitteeName from '../../../../utils/formatSectionalCommitteeName';

import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';

const LazyMarkdown = lazy(() => import('../../Forms/Elements/Markdown'));

const FilteringTable = ({
  columns,
  data,
  markDownName = '',
  componentName,
  urlString = '',
  buttonText = '',
  filter = true,
  title = 'Table Filtering',
  filterInstance = null,
}) => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { setNominationId } = useContext(CommonCollections);
  const { bodyText } = useFetchMarkdown(markDownName);
  const [isSectionalCommitteeFormatted, setIsSectionalCommitteeFormatted] =
    useLocalStorage(`${componentName}IsSectionalCommitteeFormatted`, false);

  const [pageSize, setPageSize] = useLocalStorage(
    `${componentName}PageSize`,
    10,
  );
  const [pageIndex, setPageIndex] = useLocalStorage(
    `${componentName}PageIndex`,
    0,
  );
  const [filters, setFilters] = useLocalStorage(`${componentName}Filters`, []);
  const [resetPage, setResetPage] = useState(false);

  const { columnVisibility, toggleColumnVisibility, getHiddenColumns } =
    useColumnVisibility(columns, componentName, null);

  const isAdmin =
    userDetails?.roles?.includes('super-admin') ||
    userDetails?.roles?.includes('admin');

  const memoizedColumns = useMemo(() => columns, [columns]);
  const memoizedData = useMemo(() => data, [data]);

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data: memoizedData,
      enableRowActions: true,
      initialState: {
        pageIndex,
        pageSize,
        filters,
        hiddenColumns: isAdmin ? getHiddenColumns() : [],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    setPageSize: setTablePageSize,
    rows,
    toggleHideColumn,
  } = tableInstance;

  const memoizedSetFilters = useCallback(setFilters, [setFilters]);
  const { resetFilters } = useTableFilters(
    memoizedSetFilters,
    setGlobalFilter,
    tableInstance,
  );

  useFilterInstance(
    filterInstance,
    useMemo(() => tableInstance, [tableInstance]),
    componentName,
  );

  useEffect(() => {
    applyColumnVisibility(columnVisibility, toggleHideColumn);
  }, [columnVisibility, toggleHideColumn]);

  useEffect(() => {
    if (pageIndex !== state.pageIndex) setPageIndex(state.pageIndex);
    if (pageSize !== state.pageSize) setPageSize(state.pageSize);
    if (JSON.stringify(filters) !== JSON.stringify(state.filters))
      setFilters(state.filters);

    if (resetPage && state.filters.length > 0) {
      gotoPage(0);
      setResetPage(false);
    }
  }, [
    state.pageIndex,
    state.pageSize,
    state.filters,
    gotoPage,
    setPageIndex,
    setPageSize,
    setFilters,
    resetPage,
    filters,
    pageIndex,
    pageSize,
  ]);

  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(state.filters)) {
      setFilters(state.filters);
      gotoPage(0);
    }
    setPageIndex(state.pageIndex);
    setPageSize(state.pageSize);
  }, [
    state.filters,
    state.pageIndex,
    state.pageSize,
    gotoPage,
    filters,
    setFilters,
    setPageIndex,
    setPageSize,
  ]);

  const memoizedResetFilters = useCallback(() => {
    resetFilters();
  }, [resetFilters]);

  const memoizedSetPageSize = useCallback(
    (size) => {
      setTablePageSize(size);
      setPageSize(size);
    },
    [setTablePageSize, setPageSize],
  );

  const idsString = useMemo(
    () => filters.map((filterItem) => filterItem.id).join(', '),
    [filters],
  );

  return (
    <>
      {markDownName && bodyText && (
        <div className="card">
          <div className="card-body">
            <Suspense fallback={<div>Loading Markdown...</div>}>
              <LazyMarkdown
                className="col-xl-12 col-lg-12 mt-3"
                text={bodyText}
              />
            </Suspense>
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{title}</h4>
          {urlString && (
            <Link to={`/${urlString}`} onClick={() => setNominationId(null)}>
              <button type="button" className="btn btn-success">
                <span className="btn-icon-start text-info">
                  <i className="fa fa-plus color-info" />
                </span>
                {buttonText}
              </button>
            </Link>
          )}
        </div>

        <div className="card-body">
          <FilterDisplay
            idsString={idsString}
            resetFilters={memoizedResetFilters}
          />

          <div className="row mb-xl-3">
            {filter && (
              <GlobalFilter
                filter={state.globalFilter}
                setFilter={setGlobalFilter}
              />
            )}

            {isAdmin && (
              <TableControls
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                setPageSize={memoizedSetPageSize}
                setTablePageSize={memoizedSetPageSize}
                columnVisibility={columnVisibility}
                handleColumnToggle={toggleColumnVisibility}
                filename={`${componentName}_data.csv`}
                isSectionalCommitteeFormatted={isSectionalCommitteeFormatted}
                setIsSectionalCommitteeFormatted={
                  setIsSectionalCommitteeFormatted
                }
              />
            )}
          </div>

          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table table-striped table-bordered dataTable display"
            >
              <TableHeader headerGroups={headerGroups} filter={filter} />
              <TableBody
                page={page}
                prepareRow={prepareRow}
                getTableBodyProps={getTableBodyProps}
                formatSectionalCommitteeName={formatSectionalCommitteeName}
                isSectionalCommitteeFormatted={isSectionalCommitteeFormatted}
              />
            </table>
          </div>
          <div className="mt-5">
            {pageOptions.length > 1 && (
              <PaginationControl
                pageIndex={state.pageIndex}
                pageOptions={pageOptions}
                gotoPage={gotoPage}
                nextPage={nextPage}
                previousPage={previousPage}
                canNextPage={canNextPage}
                canPreviousPage={canPreviousPage}
                pageCount={pageCount}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

FilteringTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  componentName: PropTypes.string,
  markDownName: PropTypes.string,
  urlString: PropTypes.string,
  buttonText: PropTypes.string,
  filter: PropTypes.bool,
  title: PropTypes.string,
  filterInstance: PropTypes.object,
};

export default FilteringTable;
