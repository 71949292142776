import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

const DropdownPageSize = ({ pageSize, setPageSize, setTablePageSize }) => {
  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setTablePageSize(size);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline-primary"
        id="dropdown-page-size"
        className="w-100"
      >
        Page Size: {pageSize}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {[10, 20, 30, 40, 50].map((size) => (
          <Dropdown.Item
            key={size}
            active={size === pageSize}
            onClick={() => handlePageSizeChange(size)}
          >
            {size}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownPageSize.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setTablePageSize: PropTypes.func.isRequired,
};

export default DropdownPageSize;
