import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../../utils/formatDate';
import { renderUpdatedSpan } from '../../../../utils/renderUpdatedSpan';
import { renderCustomIcon } from '../../../../utils/renderCustomIcon';
import { isDateToday } from '../../../../utils/isDateToday';

const TableRow = ({
  row,
  formatSectionalCommitteeName = null,
  isSectionalCommitteeFormatted = null,
}) => {
  const { key, ...restRowProps } = row.getRowProps();

  return (
    <tr key={key} {...restRowProps}>
      {row.cells.map((cell, columnIndex) => {
        const { key: cellKey, ...cellProps } = cell.getCellProps(); // Extract `key`

        let cellClassName = '';
        switch (cell.column.id) {
          case 'actions':
            cellClassName = 'td-center';
            break;
          case 'createdAt':
          case 'id':
          case 'nominationId':
          case 'nominationStatusTypeId':
          case 'publishedAt':
          case 'updatedAt':
            cellClassName = 'd-none';
            break;
          default:
            cellClassName = '';
            break;
        }

        let cellValue = cell.render('Cell');

        const { sectionalCommittee, sectionalCommitteeId } = row?.values || {};

        if (
          formatSectionalCommitteeName &&
          ['sectionalCommittee', 'sectionalCommitteeId'].includes(
            cell.column.id,
          ) &&
          isSectionalCommitteeFormatted
        ) {
          const valueToFormat = sectionalCommittee || sectionalCommitteeId;
          cellValue = formatSectionalCommitteeName(
            valueToFormat,
            isSectionalCommitteeFormatted,
          );
        }

        return (
          <td key={cellKey} className={cellClassName} {...cellProps}>
            {Object.values(row.values).length - 1 === columnIndex &&
            row.values.publishedAt !== undefined
              ? formatDate(row.values.publishedAt)
              : cellValue}{' '}
            {columnIndex === 1 && renderUpdatedSpan(row.values.publishedAt)}
            {cell?.column?.id === 'requestEmailDate' &&
              isDateToday(row.values.requestEmailDate) &&
              renderCustomIcon(row.values.updatedAt)}
            {cell?.column?.id === 'reminderEmailDate' &&
              isDateToday(row.values.reminderEmailDate) &&
              renderCustomIcon(row.values.updatedAt)}
            {columnIndex === 1 && row?.original?.refereeArchived && (
              <span className="archived-span">Archived</span>
            )}
          </td>
        );
      })}
    </tr>
  );
};

TableRow.propTypes = {
  row: PropTypes.object.isRequired,
  formatSectionalCommitteeName: PropTypes.func,
  isSectionalCommitteeFormatted: PropTypes.bool,
};

export default TableRow;
