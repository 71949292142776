import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { dezThemeSet } from './ThemeDemo';

export const ThemeContext = createContext();
const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  sideBarStyle: { value: 'full', label: 'Full' },
  sidebarposition: { value: 'fixed', label: 'Fixed' },
  headerposition: { value: 'fixed', label: 'Fixed' },
  sidebarLayout: { value: 'vertical', label: 'Vertical' },
  direction: { value: 'ltr', label: 'LTR' },
  primaryColor: 'color_4',
  secondaryColor: 'color_4',
  navigationHader: 'color_3',
  haderColor: 'color_3',
  sidebarColor: 'color_4',
  background: { value: 'light', label: 'Light' },
  containerPositionSize: { value: 'wide-boxed', label: 'Wide Boxed' },
  iconHover: false,
  menuToggle: false,
  windowWidth: 0,
  windowHeight: 0,
};

const ThemeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    sideBarStyle,
    sidebarposition,
    headerposition,
    sidebarLayout,
    direction,
    primaryColor,
    secondaryColor,
    navigationHader,
    haderColor,
    sidebarColor,
    background,
    containerPositionSize,
    iconHover,
    menuToggle,
    windowWidth,
    windowHeight,
  } = state;

  const body = document.querySelector('body');

  // layout
  const layoutOption = useMemo(
    () => [
      { value: 'vertical', label: 'Vertical' },
      { value: 'horizontal', label: 'Horizontal' },
    ],
    [],
  );

  const sideBarOption = useMemo(
    () => [
      { value: 'compact', label: 'Compact' },
      { value: 'full', label: 'Full' },
      { value: 'mini', label: 'Mini' },
      { value: 'modern', label: 'Modern' },
      { value: 'overlay', label: 'Overlay' },
      { value: 'icon-hover', label: 'Icon-hover' },
    ],
    [],
  );

  const backgroundOption = useMemo(
    () => [
      { value: 'light', label: 'Light' },
      { value: 'dark', label: 'Dark' },
    ],
    [],
  );

  const sidebarpositions = useMemo(
    () => [
      { value: 'fixed', label: 'Fixed' },
      { value: 'static', label: 'Static' },
    ],
    [],
  );

  const headerPositions = useMemo(
    () => [
      { value: 'fixed', label: 'Fixed' },
      { value: 'static', label: 'Static' },
    ],
    [],
  );

  const containerPosition = useMemo(
    () => [
      { value: 'wide-boxed', label: 'Wide Boxed' },
      { value: 'boxed', label: 'Boxed' },
      { value: 'wide', label: 'Wide' },
    ],
    [],
  );

  const colors = useMemo(
    () => [
      'color_1',
      'color_2',
      'color_3',
      'color_4',
      'color_5',
      'color_6',
      'color_7',
      'color_8',
      'color_9',
      'color_10',
      'color_11',
      'color_12',
    ],
    [],
  );

  const directionPosition = useMemo(
    () => [
      { value: 'ltr', label: 'LTR' },
      { value: 'rtl', label: 'RTL' },
    ],
    [],
  );

  const fontFamily = useMemo(
    () => [
      { value: 'poppins', label: 'Poppins' },
      { value: 'roboto', label: 'Roboto' },
      { value: 'nunito', label: 'Nunito' },
      { value: 'opensans', label: 'Open Sans' },
      { value: 'HelveticaNeue', label: 'HelveticaNeue' },
    ],
    [],
  );

  const changePrimaryColor = useCallback(
    (name) => {
      dispatch({ primaryColor: name });
      body.setAttribute('data-primary', name);
    },
    [dispatch, body],
  );

  const changeSecondaryColor = useCallback(
    (name) => {
      dispatch({ secondaryColor: name });
      body.setAttribute('data-secondary', name);
    },
    [dispatch, body],
  );

  const changeNavigationHader = useCallback(
    (name) => {
      dispatch({ navigationHader: name });
      body.setAttribute('data-nav-headerbg', name);
    },
    [dispatch, body],
  );

  const chnageHaderColor = useCallback(
    (name) => {
      dispatch({ haderColor: name });
      body.setAttribute('data-headerbg', name);
    },
    [dispatch, body],
  );

  const chnageSidebarColor = useCallback(
    (name) => {
      dispatch({ sidebarColor: name });
      body.setAttribute('data-sibebarbg', name);
    },
    [dispatch, body],
  );

  const changeSideBarPostion = useCallback(
    (name) => {
      dispatch({ sidebarposition: name });
      body.setAttribute('data-sidebar-position', name.value);
    },
    [dispatch, body],
  );

  const changeDirectionLayout = useCallback(
    (name) => {
      dispatch({ direction: name });
      body.setAttribute('direction', name.value);
      const html = document.querySelector('html');
      html.setAttribute('dir', name.value);
      html.className = name.value;
    },
    [dispatch, body],
  );

  const changeSideBarLayout = useCallback(
    (name) => {
      if (name.value === 'horizontal') {
        if (sideBarStyle.value === 'overlay') {
          dispatch({ sidebarLayout: name });
          body.setAttribute('data-layout', name.value);

          dispatch({ sideBarStyle: { value: 'full', label: 'Full' } });
          body.setAttribute('data-sidebar-style', 'full');
        } else {
          dispatch({ sidebarLayout: name });
          body.setAttribute('data-layout', name.value);
        }
      } else {
        dispatch({ sidebarLayout: name });
        body.setAttribute('data-layout', name.value);
      }
    },
    [dispatch, body, sideBarStyle.value],
  );

  const changeSideBarStyle = useCallback(
    (name) => {
      if (sidebarLayout.value === 'horizontal' && name.value === 'overlay') {
        alert('Sorry! Overlay is not possible in Horizontal layout.');
      } else {
        dispatch({ sideBarStyle: name });
        dispatch({ iconHover: name.value === 'icon-hover' ? '_i-hover' : '' });
        body.setAttribute('data-sidebar-style', name.value);
      }
    },
    [dispatch, body, sidebarLayout.value],
  );

  const changeHeaderPostion = useCallback(
    (name) => {
      dispatch({ headerposition: name });
      body.setAttribute('data-header-position', name.value);
    },
    [dispatch, body],
  );

  const openMenuToggle = useCallback(() => {
    dispatch({ menuToggle: sideBarStyle.value === 'overlay' });
  }, [dispatch, sideBarStyle.value]);

  const changeBackground = useCallback(
    (name) => {
      body.setAttribute('data-theme-version', name.value);
      dispatch({ background: name });
    },
    [dispatch, body],
  );

  const changeContainerPosition = useCallback(
    (name) => {
      dispatch({ containerPositionSize: name });
      body.setAttribute('data-container', name.value);
      if (name.value === 'boxed') {
        changeSideBarStyle({ value: 'overlay', label: 'Overlay' });
      }
    },
    [dispatch, body, changeSideBarStyle],
  );

  const setDemoTheme = useCallback(
    (theme, layoutDirection) => {
      const setAttr = {};
      const themeSettings = dezThemeSet[theme];

      body.setAttribute('data-typography', themeSettings.typography);

      setAttr.value = themeSettings.version;
      changeBackground(setAttr);

      setAttr.value = themeSettings.layout;
      changeSideBarLayout(setAttr);

      changePrimaryColor(themeSettings.primary);
      changeSecondaryColor(themeSettings.secondary);

      changeNavigationHader(themeSettings.navheaderBg);

      chnageHaderColor(themeSettings.headerBg);

      setAttr.value = themeSettings.sidebarStyle;
      changeSideBarStyle(setAttr);

      chnageSidebarColor(themeSettings.sidebarBg);

      setAttr.value = themeSettings.sidebarPosition;
      changeSideBarPostion(setAttr);

      setAttr.value = themeSettings.headerPosition;
      changeHeaderPostion(setAttr);

      setAttr.value = themeSettings.containerLayout;
      changeContainerPosition(setAttr);

      setAttr.value = layoutDirection;
      changeDirectionLayout(setAttr);
    },
    [
      body,
      changeBackground,
      changeSideBarLayout,
      changePrimaryColor,
      changeSecondaryColor,
      changeNavigationHader,
      chnageHaderColor,
      changeSideBarStyle,
      chnageSidebarColor,
      changeSideBarPostion,
      changeHeaderPostion,
      changeContainerPosition,
      changeDirectionLayout,
    ],
  );

  useEffect(() => {
    const bodyElement = document.querySelector('body');
    bodyElement.setAttribute('data-typography', 'poppins');
    bodyElement.setAttribute('data-theme-version', 'light');
    bodyElement.setAttribute('data-layout', 'vertical');
    bodyElement.setAttribute('data-primary', 'color_4');
    bodyElement.setAttribute('data-nav-headerbg', 'color_4');
    bodyElement.setAttribute('data-headerbg', 'color_4');
    bodyElement.setAttribute('data-sidebar-style', 'overlay');
    bodyElement.setAttribute('data-sibebarbg', 'color_4');
    bodyElement.setAttribute('data-secondary', 'color_4');
    bodyElement.setAttribute('data-sidebar-position', 'fixed');
    bodyElement.setAttribute('data-header-position', 'fixed');
    bodyElement.setAttribute('data-container', 'wide');
    bodyElement.setAttribute('direction', 'ltr');

    const resizeWindow = () => {
      dispatch({ windowWidth: window.innerWidth });

      dispatch({ windowHeight: window.innerHeight });

      if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        bodyElement.setAttribute('data-sidebar-style', 'mini');
      } else if (window.innerWidth <= 768) {
        bodyElement.setAttribute('data-sidebar-style', 'overlay');
      } else {
        bodyElement.setAttribute('data-sidebar-style', 'full');
      }
    };
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const providerValue = useMemo(
    () => ({
      body,
      sideBarOption,
      layoutOption,
      backgroundOption,
      sidebarposition,
      headerPositions,
      containerPosition,
      directionPosition,
      fontFamily,
      primaryColor,
      secondaryColor,
      navigationHader,
      windowWidth,
      windowHeight,
      changePrimaryColor,
      changeSecondaryColor,
      changeNavigationHader,
      changeSideBarStyle,
      sideBarStyle,
      changeSideBarPostion,
      sidebarpositions,
      changeHeaderPostion,
      headerposition,
      changeSideBarLayout,
      sidebarLayout,
      changeDirectionLayout,
      changeContainerPosition,
      direction,
      colors,
      haderColor,
      chnageHaderColor,
      chnageSidebarColor,
      sidebarColor,
      iconHover,
      menuToggle,
      openMenuToggle,
      changeBackground,
      background,
      containerPositionSize,
      setDemoTheme,
    }),
    [
      body,
      sideBarOption,
      layoutOption,
      backgroundOption,
      sidebarposition,
      headerPositions,
      containerPosition,
      directionPosition,
      fontFamily,
      primaryColor,
      secondaryColor,
      navigationHader,
      windowWidth,
      windowHeight,
      changePrimaryColor,
      changeSecondaryColor,
      changeNavigationHader,
      changeSideBarStyle,
      sideBarStyle,
      changeSideBarPostion,
      sidebarpositions,
      changeHeaderPostion,
      headerposition,
      changeSideBarLayout,
      sidebarLayout,
      changeDirectionLayout,
      changeContainerPosition,
      direction,
      colors,
      haderColor,
      chnageHaderColor,
      chnageSidebarColor,
      sidebarColor,
      iconHover,
      menuToggle,
      openMenuToggle,
      changeBackground,
      background,
      containerPositionSize,
      setDemoTheme,
    ],
  );

  return (
    <ThemeContext.Provider value={providerValue}>
      {children}
    </ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeContextProvider;
