import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Label from '../Elements/Label';
import Select from '../Elements/Select';
import Span from '../Elements/Span';

import { Context } from '../Wizard/WizardWrapper';
import InputFile from '../Elements/InputFile';
import InputText from '../Elements/InputText';
import { strapiUrl } from '../../../../utils/constants';
import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';
import { axiosGet } from '../../../../utils/axiosGet';
import DownloadFile from '../Elements/DownloadFile';
import Radio from '../Elements/Radio';
import { useStaticCollections } from '../../../../context/StaticCollections';
import { getReportRoundYear } from '../../../../utils/getReportRoundYear';

const RefereeActions = ({ item }) => {
  const { errors, resetField, formState, trigger, watch } = useContext(Context);
  const { refereeStatuses } = useStaticCollections();
  const [inputDisabled, setInputDisabled] = useState(false);
  const [declineReasonValue, setDeclineReasonValue] = useState('');
  const [edit, setEdit] = useState(false);
  const refereeActions = watch('refereeActions');
  const { userDetails } = useLoggedInUserContextProvider();
  const isAdmin =
    userDetails.roles.includes('admin') ||
    userDetails.roles.includes('super-admin');
  const { nomination } = formState || {};
  const canEditNomination =
    nomination?.attributes?.nominationStatusTypeId !== 4 &&
    nomination?.attributes?.nominationStatusTypeId !== 5 &&
    nomination?.attributes?.nominationStatusTypeId !== 6;
  const canEdit = isAdmin && canEditNomination;
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const { refereeStatusId, declineReasonId } = formState.attributes || {};
      const shouldDisableInput =
        refereeStatusId !== 1 && refereeStatusId !== 6 && refereeStatusId !== 5;
      setInputDisabled(shouldDisableInput);

      if (refereeStatusId === 3 && declineReasonId) {
        try {
          const { data } = await axiosGet(
            `referee-report-decline-reasons/${declineReasonId}`,
          );
          setDeclineReasonValue(data.data.attributes.name);
        } catch (error) {
          console.error(
            'An error occurred while fetching decline reason:',
            error,
          );
        }
      }
    };

    fetchData();
  }, [formState]);

  useEffect(() => {
    trigger();
  }, [refereeActions, trigger]);

  const handleEditToggle = () => setEdit((prevEdit) => !prevEdit);

  const setResetValues = (
    currentOptions,
    eventTargetName,
    eventTargetValue,
  ) => {
    const selectedOption = currentOptions.find(
      (option) => option.id === eventTargetValue,
    );

    if (eventTargetName === 'refereeActions') {
      formState.refereeActions = selectedOption.id;
      [
        'extensionReason',
        'report',
        'reportFileId',
        'reportFileName',
        'reportFilePath',
        'declineReasonId',
        'declineReasonOther',
      ].forEach(resetField);
    }

    if (eventTargetName === 'declineReasonId') {
      formState.attributes.declineReasonId = selectedOption
        ? selectedOption.id
        : null;
    }

    trigger();
  };

  const renderFileUploadSection = () => {
    if (selectedValue !== 2) return null;

    return (
      <div>
        {(inputDisabled === false || edit === true) && (
          <InputFile
            name="report"
            fileExtension={[{ id: 1, attributes: { name: 'pdf' } }]}
          />
        )}
        {errors.report && <Span text={errors.report.message} />}
      </div>
    );
  };

  const renderExtensionReasonSection = () => {
    if (selectedValue !== 5) return null;

    return (
      <div className="col-lg-6 mt-4">
        <b>
          <Label
            label="Please provide a reason and indicate when you will be able to submit the report"
            name="extensionReasonLabel"
            required={item.required}
          />
        </b>
        <InputText name="extensionReason" />
        {errors.extensionReason && (
          <Span text={errors.extensionReason.message} />
        )}
      </div>
    );
  };

  const renderDeclineReasonSection = () => {
    if ((inputDisabled === false || edit === true) && selectedValue === 3) {
      return (
        <div className="col-lg-6 mt-4">
          <b>
            <Label label="Decline Reason" name="declineReasonId" />
          </b>
          <br />
          <Select
            name="declineReasonId"
            options="referee-report-decline-reasons"
            setState={setResetValues}
          />
          {formState?.attributes?.declineReasonId === 5 && (
            <>
              <b>
                <Label
                  label="Please enter your decline reason"
                  name="declineReasonOther"
                />
              </b>
              <InputText name="declineReasonOther" />
              {errors.declineReasonOther && (
                <Span text={errors.declineReasonOther.message} />
              )}
            </>
          )}
          {errors.declineReasonId && (
            <Span text={errors.declineReasonId.message} />
          )}
        </div>
      );
    }

    return null;
  };

  const renderUploadedFileInfo = () => {
    if (formState.attributes?.report?.data) {
      const nominationRoundYear = getReportRoundYear(formState.attributes);
      return (
        <div className="mt-2 mb-2">
          <h3>Most Recent Upload:</h3>
          <DownloadFile
            text={formState.attributes.report.data.attributes.name}
            url={`${strapiUrl}${formState.attributes.report.data.attributes.url}`}
          />
          <p className="uploaded-file">
            {' '}
            Uploaded round year : {nominationRoundYear}
          </p>
        </div>
      );
    }
    return null;
  };

  const renderDeclineInfo = () => {
    const { refereeStatusId, declineReasonOther, refereeDeclineSubmittedYear } =
      formState.attributes || {};
    if (inputDisabled === true && refereeStatusId === 3) {
      return (
        <div className="mt-4">
          <h3> Referee Report Request Declined</h3>

          <Label label="Decline Reason : " name="declineReasonId" />

          <p>{declineReasonOther || declineReasonValue}</p>

          <Label label="Declined On : " name="declineReasonId" />

          <p>{refereeDeclineSubmittedYear}</p>
        </div>
      );
    }
    return null;
  };

  const renderExtensionInfo = () => {
    const { refereeStatusId, extensionReason } = formState.attributes || {};
    if (inputDisabled === true && refereeStatusId === 5) {
      return (
        <>
          <b>
            <Label
              label="Referee Report Extension Reason : "
              name="refereeExtensionId"
            />
          </b>
          <p>{extensionReason}</p>
        </>
      );
    }
    return null;
  };

  const renderApprovalNoteInfo = () => {
    const { refereeStatusId, extensionReasonApprovalNote } =
      formState.attributes || {};
    if (inputDisabled === true && refereeStatusId === 6) {
      return (
        <>
          <b>
            <Label
              label="Referee Report Approval Note : "
              name="refereeExtensionApprovalNoteId"
            />
          </b>
          <p>{extensionReasonApprovalNote}</p>
        </>
      );
    }
    return null;
  };

  return (
    <React.Fragment key={`referee-field-collection-${item.name}`}>
      <div>
        {renderUploadedFileInfo()}
        <div className="form-group mt-4 d-flex justify-content-between">
          <div>
            <span className="badge badge-info m-1">
              {formState?.attributes?.refereeStatusId
                ? refereeStatuses[formState.attributes.refereeStatusId]
                : ''}
            </span>
          </div>

          {inputDisabled && canEdit && (
            <button
              onClick={handleEditToggle}
              type="button"
              className={`btn btn-${edit ? 'danger' : 'success'}`}
            >
              <span>
                <i className={`fa fa-${edit ? 'x' : 'pencil'}`} />{' '}
                {edit ? 'Cancel' : 'Edit'}
              </span>
            </button>
          )}
        </div>
        {renderDeclineInfo()}
        {renderExtensionInfo()}
        {renderApprovalNoteInfo()}
        {(inputDisabled === false || edit === true) && (
          <div className="mt-2">
            <h3>
              <Label
                label="Please upload a file, decline, use existing report or request extension below"
                name="refereeActions"
                required={item.required}
              />
            </h3>
            <div className="form-group col-lg-12">
              <Radio
                options="referee-actions"
                name="refereeActions"
                setSelectedValue={setSelectedValue}
              />

              {errors.refereeActions && (
                <Span text={errors.refereeActions.message} />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="form-group mt-4">
        {renderFileUploadSection()}
        {renderExtensionReasonSection()}
        {renderDeclineReasonSection()}
      </div>
    </React.Fragment>
  );
};

RefereeActions.propTypes = {
  item: PropTypes.object,
};

export default RefereeActions;
