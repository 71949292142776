import React, { useContext } from 'react';
import { Card, Table } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageTitle from '../layouts/PageTitle';
import { CommonCollections } from '../../App';

import { strapiUrl } from '../../utils/constants';
import SectionalCommittees from './SectionalCommittees';

import DownloadFile from './Forms/Elements/DownloadFile';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';
import { getReportRoundYear } from '../../utils/getReportRoundYear';

const TableIndependentAssessor = ({ nominationsInSectionalCommittee }) => {
  const { activeFellows, declineReasons, nonAasReferees, refereeStatuses } =
    useStaticCollections();
  const { candidates } = useAdminCollections();
  const { refereeReports, setNominationId } = useContext(CommonCollections);

  const { userDetails } = useLoggedInUserContextProvider();
  return (
    <>
      {nominationsInSectionalCommittee.map((nomination) => {
        const filteredReports = refereeReports.filter(
          (report) =>
            report.attributes.nominationId === nomination.id &&
            !report.attributes.refereeArchived,
        );
        let linkAssessorText = 'Add assessors';
        let linkAssessorIcon = 'plus';

        if (
          (nomination.attributes.electionTypeId === 2 &&
            filteredReports.length > 6) ||
          (nomination.attributes.electionTypeId === 1 &&
            filteredReports.length > 4)
        ) {
          linkAssessorText = 'Edit assessors';
          linkAssessorIcon = 'pencil';
        }

        return (
          <Card
            key={nomination.id}
            className="card-two mb-xl-5 table-responsive"
          >
            <Card.Body>
              <Card.Title className="mb-xl-4">
                <div className="row">
                  <div className="col d-flex justify-content-between align-items-center">
                    <div>
                      {candidates[nomination.attributes.candidateId]}
                      <Link
                        to="/form-view"
                        onClick={() => setNominationId(nomination.id)}
                      >
                        <span title="View">
                          <i className="bi bi-eye view-button" />
                        </span>
                      </Link>
                    </div>

                    {userDetails &&
                      ['committee-chair', 'admin', 'super-admin'].some((role) =>
                        userDetails.roles?.includes(role),
                      ) && (
                        <Link
                          to="/independent-assessor"
                          onClick={() => setNominationId(nomination.id)}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              setNominationId(nomination.id);
                            }
                          }}
                        >
                          <button type="button" className="btn btn-success">
                            <span className="btn-icon-start text-info">
                              <i
                                className={`fa fa-${linkAssessorIcon} color-info`}
                              />
                            </span>
                            {linkAssessorText}
                          </button>
                        </Link>
                      )}
                  </div>
                </div>
              </Card.Title>
              <Table bordered hover striped className="dataTable">
                <thead className="thead-dark">
                  <tr>
                    <th>
                      <b>Name</b>
                    </th>
                    <th>
                      <b>Status</b>
                    </th>
                    <th>
                      <b>Type</b>
                    </th>
                    <th>
                      <b>Round</b>
                    </th>
                    <th aria-label="Actions">
                      <b />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredReports.map((referee, index) => {
                    const reportYear = getReportRoundYear(referee.attributes);
                    const candidateName =
                      candidates[nomination.attributes.candidateId];
                    const candidateWithoutSpaces = candidateName
                      ? candidateName.replace(/\s/g, '')
                      : '';
                    const refereeName = referee.attributes.refereeFellowId
                      ? activeFellows[referee.attributes.refereeFellowId]
                      : nonAasReferees[referee.attributes.nonAasRefereeId];

                    let reason = '';
                    const {
                      refereeStatusId,
                      declineReasonId,
                      declineReasonOther,
                      extensionReason,
                      extensionReasonApprovalNote,
                    } = referee.attributes;

                    if (refereeStatusId === 3) {
                      reason =
                        declineReasonId === 5
                          ? declineReasonOther
                          : declineReasons[declineReasonId];
                    } else if (refereeStatusId === 5) {
                      reason = extensionReason;
                    } else if (refereeStatusId === 6) {
                      reason = extensionReasonApprovalNote;
                    }
                    return (
                      <tr key={index}>
                        <td>{refereeName}</td>
                        <td>
                          <span>
                            {reason ? (
                              <>
                                <span>
                                  <b>
                                    {
                                      refereeStatuses[
                                        referee.attributes.refereeStatusId
                                      ]
                                    }
                                    {' : '}
                                  </b>
                                </span>
                                {refereeStatusId === 6 ? (
                                  <>
                                    <br />
                                    <span>Reason: {extensionReason}</span>
                                    <br />
                                    <span>
                                      Approval Note:{' '}
                                      {extensionReasonApprovalNote}
                                    </span>
                                  </>
                                ) : (
                                  <span>{reason}</span>
                                )}
                              </>
                            ) : (
                              <span>
                                {
                                  refereeStatuses[
                                    referee.attributes.refereeStatusId
                                  ]
                                }
                              </span>
                            )}
                          </span>
                        </td>
                        <td>
                          {referee.attributes.independentAssessor === true
                            ? 'Independent Assessor'
                            : 'Referee'}
                        </td>
                        <td>{reportYear}</td>
                        <td className="text-center">
                          {referee.attributes.report.data !== null && (
                            <DownloadFile
                              text={`Report Uploaded Year : ${reportYear}`}
                              url={`${strapiUrl}${referee.attributes.report.data.attributes.url}`}
                              name={`${candidateWithoutSpaces}${refereeName}`}
                              buttonClassName="action-button-two"
                              iconClassName="download-button"
                              showFileName={false}
                              showText={false}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
};

const IndependentAssessor = () => (
  <>
    <PageTitle motherMenu="Nominations" activeMenu="Independent Assessors" />
    <SectionalCommittees
      TableComponent={TableIndependentAssessor}
      statusTypes={[3]}
    />
  </>
);

TableIndependentAssessor.propTypes = {
  nominationsInSectionalCommittee: PropTypes.array,
};

export default IndependentAssessor;
