import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DropdownViewMode = ({
  isSectionalCommitteeFormatted,
  setIsSectionalCommitteeFormatted,
}) => (
  <div className="table-control">
    <Dropdown>
      <Dropdown.Toggle variant="outline-primary" className="w-100">
        <i
          className={`bi ${isSectionalCommitteeFormatted ? 'bi-dash-circle' : 'bi-plus-circle'} me-2`}
        />
        {isSectionalCommitteeFormatted ? 'Compact' : 'Expanded'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => setIsSectionalCommitteeFormatted(true)}
          active={isSectionalCommitteeFormatted}
        >
          Compact
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => setIsSectionalCommitteeFormatted(false)}
          active={!isSectionalCommitteeFormatted}
        >
          Expanded
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

DropdownViewMode.propTypes = {
  isSectionalCommitteeFormatted: PropTypes.bool.isRequired,
  setIsSectionalCommitteeFormatted: PropTypes.func.isRequired,
};

export default DropdownViewMode;
