import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({ headerGroups, filter = false }) => (
  <thead className="thead-dark">
    {headerGroups.map((headerGroup) => {
      const { key, ...restHeaderGroupProps } =
        headerGroup.getHeaderGroupProps();

      return (
        <tr key={key} {...restHeaderGroupProps}>
          {headerGroup.headers.map((column) => {
            const { key: columnKey, ...restColumnProps } =
              column.getHeaderProps(column.getSortByToggleProps());

            let tableHeaderClassName = '';
            switch (column.id) {
              case 'createdAt':
              case 'id':
              case 'nominationId':
              case 'nominationStatusTypeId':
              case 'publishedAt':
              case 'updatedAt':
                tableHeaderClassName = 'd-none';
                break;
              default:
                tableHeaderClassName = '';
                break;
            }

            let sortIndicator = '';
            if (column.isSorted) {
              sortIndicator = column.isSortedDesc ? ' 🔽' : ' 🔼';
            }

            return (
              <th
                key={columnKey}
                {...restColumnProps}
                className={tableHeaderClassName}
              >
                {column.render('Header')}
                {sortIndicator}
                {filter && column.canFilter && (
                  <div
                    tabIndex="0"
                    role="button"
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                  >
                    {column.render('Filter')}
                  </div>
                )}
              </th>
            );
          })}
        </tr>
      );
    })}
  </thead>
);

TableHeader.propTypes = {
  headerGroups: PropTypes.array.isRequired,
  filter: PropTypes.bool,
};

export default TableHeader;
