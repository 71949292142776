import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

const DropdownColumnVisibility = ({
  columns,
  columnVisibility,
  handleColumnToggle,
}) => {
  const filterColumns = (col) =>
    (!col.accessor.includes('Id') ||
      col.accessor === 'sectionalCommitteeId' ||
      col.accessor === 'electionTypeId') &&
    !col.accessor.includes('At') &&
    col.accessor !== 'id' &&
    !col.accessor.includes('actions') &&
    !col.accessor.includes('candidate');

  const isChecked = (col) => {
    if (Object.keys(columnVisibility).length === 0) {
      return true;
    }

    if (!(col.accessor in columnVisibility)) {
      return true;
    }

    return columnVisibility[col.accessor];
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline-primary"
        id="column-visibility-dropdown"
        className="w-100"
      >
        Toggle Columns
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {columns &&
          columns.filter(filterColumns).map((col) => (
            <Dropdown.Item
              as="label"
              key={col.accessor}
              className="d-flex align-items-center"
              onClick={(e) => e.stopPropagation()}
            >
              <input
                type="checkbox"
                checked={isChecked(col)}
                onChange={() => handleColumnToggle(col.accessor)}
                className="me-1 custom-checkbox"
              />
              <span className="ms-1">{col.Header}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownColumnVisibility.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      Header: PropTypes.string.isRequired,
    }),
  ).isRequired,
  columnVisibility: PropTypes.objectOf(PropTypes.bool).isRequired,
  handleColumnToggle: PropTypes.func.isRequired,
};

export default DropdownColumnVisibility;
