const formatSectionalCommitteeName = (sectionalCommitteeName, shouldFormat) => {
  if (shouldFormat && typeof sectionalCommitteeName === 'string') {
    if (sectionalCommitteeName.includes(':')) {
      return sectionalCommitteeName.split(':')[0];
    }
  }
  return sectionalCommitteeName;
};

export default formatSectionalCommitteeName;
