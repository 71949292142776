/**
 * Checks if the given date string in DD/MM/YYYY format is today's date.
 *
 * @param {string} dateString - The date string in DD/MM/YYYY format.
 * @returns {boolean} - Returns true if the date matches today's date, false otherwise.
 */
export const isDateToday = (dateString) => {
  if (!dateString) return false;

  const [day, month, year] = dateString.split('/').map(Number);

  if (
    !day ||
    !month ||
    !year ||
    day < 1 ||
    day > 31 ||
    month < 1 ||
    month > 12 ||
    year < 1000
  ) {
    return false;
  }

  const date = new Date(year, month - 1, day);
  const today = new Date();

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};
