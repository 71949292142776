import React from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';

const TableBody = ({
  page,
  prepareRow,
  getTableBodyProps,
  formatSectionalCommitteeName = null,
  isSectionalCommitteeFormatted = null,
}) => (
  <tbody {...getTableBodyProps()}>
    {page.map((row) => {
      prepareRow(row);
      return (
        <TableRow
          key={row.id}
          row={row}
          formatSectionalCommitteeName={formatSectionalCommitteeName}
          isSectionalCommitteeFormatted={isSectionalCommitteeFormatted}
        />
      );
    })}
  </tbody>
);

TableBody.propTypes = {
  page: PropTypes.array.isRequired,
  prepareRow: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  formatSectionalCommitteeName: PropTypes.func,
  isSectionalCommitteeFormatted: PropTypes.bool,
};

export default TableBody;
