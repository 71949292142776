import { useEffect, useState } from 'react';
import { fetchData } from '../../utils/fetchData';

const useFetchMarkdown = (markDownName) => {
  const [pageFields, setPageFields] = useState([]);

  useEffect(() => {
    if (!markDownName) {
      setPageFields([]);
      return;
    }

    fetchData(
      `markdowns?filters[name][$eq]=${markDownName}&populate=*`,
      setPageFields,
    );
  }, [markDownName]);

  const bodyText = pageFields[0]?.attributes?.content || null;

  return { bodyText };
};

export default useFetchMarkdown;
