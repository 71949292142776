/**
 * Renders a span element with a Bootstrap email icon if the provided date falls within the specified range.
 *
 * @param {string | Date} updatedAt - The date to check against the range. Can be a string (ISO format) or a Date object.
 * @param {number} [rangeInMinutes=5] - The range in minutes to determine if the icon should be shown.
 *                                      Defaults to 5 minutes if not provided.
 * @returns {JSX.Element | null} - Returns a <span> element with a Bootstrap email icon if the `updatedAt` date
 *                                 falls within the range; otherwise, returns `null`.
 */
export const renderCustomIcon = (
  updatedAt,
  bootstrapIcon = 'bi-check-circle-fill',
  rangeInMinutes = 5,
) => {
  if (!updatedAt) return null;

  const now = new Date();
  const rangeStart = new Date(now.getTime() - rangeInMinutes * 60 * 1000);
  const updatedDate = new Date(updatedAt);

  if (Number.isNaN(updatedDate.getTime())) {
    console.warn('Invalid date provided:', updatedAt);
    return null;
  }

  if (updatedDate > rangeStart && updatedDate <= now) {
    return (
      <span className="email-sent-icon text-success">
        <i className={`bi ${bootstrapIcon}`} aria-label="Email Sent" />
      </span>
    );
  }

  return null;
};
