import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  strapiApiUrl,
  fileUploadMaxSize,
  refereeReportFileUploadMaxSize,
} from '../../../../utils/constants';
import { Context } from '../Wizard/WizardWrapper';
import { axiosDelete } from '../../../../utils/axiosDelete';
import { axiosPost } from '../../../../utils/axiosPost';

import DownloadFile from './DownloadFile';
import ModalConfirmation from '../../ModalConfirmation';

const InputFile = ({ name, fileExtension, disabled = false }) => {
  const [showModal, setShowModal] = useState(false);
  const { register, watch, setValue, resetField, setError, trigger } =
    useContext(Context);

  const htmlFileExtensions = fileExtension
    .map((item) => `.${item.attributes.name}`)
    .join(' ');

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  const deleteExistingFile = async (fieldName) => {
    const existingFileId = watch(`${fieldName}FileId`).id;
    const deletedFile = await axiosDelete(`upload/files/${existingFileId}`);

    if (deletedFile?.status === 200) {
      resetField(`${fieldName}`);
      resetField(`${fieldName}FileId`);
      resetField(`${fieldName}FileName`);
      resetField(`${fieldName}FilePath`);
      setShowModal(false);
      trigger();

      const button = document.querySelector(
        '.btn.btn-secondary.sw-btn-next.ms-1',
      );

      if (button) {
        setTimeout(() => {
          button.click();
        }, 700);
      }
    }
  };

  const inputFileHandler = (event) => {
    const currentFileExtension = event?.target?.files?.[0]?.type;

    const fieldExtensionOptions = fileExtension.map(
      (item) => item.attributes.name,
    );

    const extensionExists = fieldExtensionOptions.some((option) =>
      currentFileExtension.includes(option),
    );

    if (extensionExists !== undefined && extensionExists === false) {
      setValue(event.target.name, '', { shouldValidate: true });
    }

    if (
      (extensionExists &&
        event?.target?.files?.[0]?.size !== 0 &&
        event?.target?.files?.[0]?.size <= fileUploadMaxSize) ||
      (name === 'report' &&
        event?.target?.files?.[0]?.size <= refereeReportFileUploadMaxSize)
    ) {
      const formData = new FormData();
      formData.append('files', event?.target?.files?.[0]);

      const valueTest = event?.target?.files?.[0]?.name;
      setValue(`${event.target.name}FileName`, valueTest);

      if (
        watch(`${name}FileId`) &&
        typeof watch(`${name}FileId`) === 'object' &&
        Object.values(watch(`${name}FileId`)).length !== 0
      ) {
        deleteExistingFile(watch(`${name}FileId`).id, name);
      }

      axiosPost('upload', formData)
        .then((response) => {
          if (response && response?.status === 200 && response.data?.[0]?.id) {
            setValue(`${event.target.name}FileId`, { id: response.data[0].id });
            setValue(
              `${event.target.name}FilePath`,
              `${strapiApiUrl.replace('/api/', '')}${response.data[0].url}`,
            );
            trigger();
          } else {
            console.log(`File ${event.target.name} was not uploaded`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (
      (watch(name) && Object.keys(watch(name)).length === 0) ||
      typeof watch(`${name}FileId`) === 'string'
    ) {
      resetField(`${name}FileId`);
      resetField(`${name}FileName`);
      resetField(`${name}FilePath`);
    }
  }, [name, resetField, watch]);

  return (
    <>
      <input
        name={`${name}FileId`}
        type="hidden"
        {...register(`${name}FileId`)}
      />
      <input
        name={`${name}FileName`}
        type="hidden"
        {...register(`${name}FileName`)}
      />
      <input
        name={`${name}FilePath`}
        type="hidden"
        {...register(`${name}FilePath`)}
      />

      <input
        name={name}
        type="file"
        className={`form-control mb-3 ${
          watch(`${name}FileName`) !== undefined &&
          watch(`${name}FileName`).trim().length !== 0
            ? 'successful-upload'
            : ''
        }`}
        placeholder="upload"
        accept={
          htmlFileExtensions !== undefined && htmlFileExtensions !== ''
            ? htmlFileExtensions
            : ''
        }
        {...register(name, {
          onChange: (event) => inputFileHandler(event),
        })}
        disabled={disabled}
      />

      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText="Are you sure you want to **delete** this file? **this action cannot be undone**"
        handleConfirmSelect={deleteExistingFile}
        rowId={name}
      />

      {watch(`${name}FileName`) &&
      watch(`${name}FilePath`) &&
      watch(`${name}FileId`) ? (
        <div>
          <DownloadFile
            text={watch(`${name}FileName`)}
            url={watch(`${name}FilePath`)}
            name={name}
          />
          <button
            type="button"
            className="btn btn-outline-danger fns-delete-icon"
            onClick={() => {
              setShowModal(true);

              if (!watch(`${name}`)) {
                setError(name, {
                  message: 'Required Field',
                  type: 'required',
                  ref: { name },
                });
              }
            }}
          >
            <i className="bi bi-trash" />
            <span className="visually-hidden">Button</span>
          </button>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

InputFile.propTypes = {
  name: PropTypes.string,
  fileExtension: PropTypes.array,
  disabled: PropTypes.bool,
};

export default InputFile;
