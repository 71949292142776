import { axiosPost } from './axiosPost';
import { axiosPut } from './axiosPut';

const handleApiResponse = (
  response,
  setRefreshCollections,
  refreshCollections,
  setShowModal,
) => {
  if (response.status === 200) {
    if (response.data) {
      setRefreshCollections(!refreshCollections);
      setShowModal(false);
    } else {
      console.error('Response data is missing or not in the expected format.');
    }
  } else {
    console.error('Unexpected status:', response.status);
  }
};

export const handleConfirmSelect = async (
  rowId,
  apiIdentifier,
  setRefreshCollections,
  refreshCollections,
  setShowModal,
) => {
  try {
    if (typeof rowId === 'string') {
      let response;

      if (apiIdentifier === 'nomination-drafts' && rowId.includes('d')) {
        const draftNominationRowId = parseInt(rowId.replace(/d/g, ''));

        response = await axiosPut(`${apiIdentifier}/${draftNominationRowId}`, {
          data: {
            publishedAt: null,
          },
        });
      } else if (!rowId.includes('d') && apiIdentifier) {
        response = await axiosPost(apiIdentifier, { data: { rowId } });
      }

      if (response) {
        handleApiResponse(
          response,
          setRefreshCollections,
          refreshCollections,
          setShowModal,
        );
      }
    }
  } catch (error) {
    console.error('Error occurred:', error);
  }
};
