import React from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from '../../Forms/Elements/ButtonIcon';

const FilterDisplay = ({ idsString, resetFilters }) => {
  if (!idsString) return null;

  return (
    <div className="mb-xl-5">
      <h5 className="card-title text-capitalize">
        Filters{' '}
        <ButtonIcon
          bootstrapIcon="bi-arrow-counterclockwise"
          backgroundColor="bg-success"
          handleClick={resetFilters}
          handleKeyPress={resetFilters}
          tooltipText="Reset filters"
        />
      </h5>
      <p className="card-text">
        <span>{idsString}</span>
      </p>
    </div>
  );
};

FilterDisplay.propTypes = {
  idsString: PropTypes.string,
  resetFilters: PropTypes.func,
};

export default FilterDisplay;
