import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import PropTypes from 'prop-types';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';

const ModalConfirmation = ({
  showModal,
  handleCancelSelect,
  bodyText,
  handleConfirmSelect,
  rowId,
  apiIdentifier = '',
  setRefreshCollections = '',
  refreshCollections = '',
  setShowModal = '',
  inputValue,
  setInputValue,
  showForm = false,
}) => {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (showForm && !inputValue.trim()) {
      setValidated(true);
    } else {
      setValidated(false);
      handleConfirmSelect(rowId);
    }
  };

  useEffect(() => {
    if (!showModal) {
      setIsLoading(false);
    }
  }, [showModal]);

  return (
    <Modal show={showModal} onHide={handleCancelSelect} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Selection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{bodyText}</ReactMarkdown>

        {showForm && (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="inputValue">
              <Form.Label>Please provide a reason</Form.Label>
              <Form.Control
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Required
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
        {isLoading && (
          <div className="d-flex justify-content-center mt-3">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCancelSelect}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            setIsLoading(true);

            handleConfirmSelect(
              rowId,
              apiIdentifier,
              setRefreshCollections,
              refreshCollections,
              setShowModal,
            );
          }}
          disabled={isLoading}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalConfirmation.propTypes = {
  showModal: PropTypes.bool,
  handleCancelSelect: PropTypes.func,
  bodyText: PropTypes.string,
  handleConfirmSelect: PropTypes.func,
  apiIdentifier: PropTypes.string,
  rowId: PropTypes.string,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  showForm: PropTypes.bool,
  setRefreshCollections: PropTypes.func,
  refreshCollections: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default ModalConfirmation;
